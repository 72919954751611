import './App.css';
import KycPanel from "./KycPanel";

function App() {
    return (
        <div className="App">
            <KycPanel/>
        </div>
    );
}

export default App;

