import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';

import styled from 'styled-components';
import axios from 'axios'
import snsWebSdk from '@sumsub/websdk';
import iproxy_kyc from './iproxy_kyc.jpg'

export default function KycPanel(props) {
    const [data, setData] = useState();

    useEffect(() => {
        if (gup('token') == undefined){
            return;
        }
        getTokenData().then(x => {
            setData(x);
            launchWebSdk(x.sumsub_token, x.email);
        });
    }, []);

    let token = data?.sumsub_token;

    return (
        <Wrapper>

            {token == undefined ? 'loading ...' :
                <TopLogoPlaceholder>
                    <img style={{height: 50}} src={iproxy_kyc} />
                    <p>
                        We need to verify your identity.
                        Please follow steps below.
                    </p>
                </TopLogoPlaceholder>
            }

            <div id="sumsub-websdk-container"></div>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TopLogo = styled.img`
    margin: 15px;
`;

const TopLogoPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
}

function launchWebSdk(accessToken, applicantEmail, applicantPhone, customI18nMessages) {
    let snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => getTokenData()
    )
        .withConf({
            lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
            email: applicantEmail,
            phone: applicantPhone,
            i18n: customI18nMessages, //JSON of custom SDK Translations
            uiConf: {
                customCss: "https://url.com/styles.css"
                // URL to css file in case you need change it dynamically from the code
                // the similar setting at Customizations tab will rewrite customCss
                // you may also use to pass string with plain styles `customCssStr:`
            },
        })
        .withOptions({addViewportTag: false, adaptIframeHeight: true})
        // see below what kind of messages WebSDK generates
        .on('idCheck.stepCompleted', (payload) => {
            console.log('stepCompleted', payload)
        })
        .on('idCheck.onError', (error) => {
            console.log('onError', error)
        })
        .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
}

async function getTokenData() {
    let token = gup('token');
    let t = (await axios.get(`https://iproxy.online/api-rt/sumsub/websdk-details?token=${token}`)).data;
    return t;
}

async function getToken() {
    let d = await getTokenData();
    return d.sumsub_token;
}


// function getNewAccessToken() {
//     return Promise.resolve(newAccessToken)// get a new token from your backend
// }